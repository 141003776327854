/**
* Template Name: NiceAdmin - v2.4.0
* Template URL: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

@import "colors";

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: "Open Sans", sans-serif;
    background: $main-background;
    color: $main-font-color;
  }
  
  a {
    color: $main-link-color;
    text-decoration: none;
  
    &:hover {
      color: $main-link-hover-color;
      text-decoration: none;
    }
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Nunito", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
  
  #main {
    margin-top: 60px;
    padding: 20px 30px;
    transition: all 0.3s;
  }
  
  @media (max-width: 1199px) {
    #main {
      padding: 20px;
    }
  }
  
  /*--------------------------------------------------------------
  # Page Title
  --------------------------------------------------------------*/
  
  .pagetitle {
    margin-bottom: 10px;
  
    h1 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 600;
      color: $main-title-color;
    }
  }
  
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background: $primary;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  
    i {
      font-size: 24px;
      color: #fff;
      line-height: 0;
    }
  
    &:hover {
      background: $main-title-color;
      color: #fff;
    }
  
    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
  
  /*--------------------------------------------------------------
  # Override some default Bootstrap stylings
  --------------------------------------------------------------*/
  /* Dropdown menus */
  
  .dropdown-menu {
    border-radius: 4px;
    padding: 10px 0;
    -webkit-animation-name: dropdown-animate;
    animation-name: dropdown-animate;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border: 0;
    box-shadow: 0 5px 30px 0 rgba(82, 63, 105, 0.2);
  
    .dropdown-header {
      text-align: center;
      font-size: 15px;
      padding: 10px 25px;
    }
  
    .dropdown-footer {
      text-align: center;
      font-size: 15px;
      padding: 10px 25px;
  
      a {
        color: #444444;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }
    }
  
    .dropdown-divider {
      color: #a5c5fe;
      margin: 0;
    }
  
    .dropdown-item {
      font-size: 14px;
      padding: 10px 15px;
      transition: 0.3s;
  
      i {
        margin-right: 10px;
        font-size: 18px;
        line-height: 0;
      }
  
      &:hover {
        background-color: $main-background;
      }
    }
  }
  
  @media (min-width: 768px) {
    .dropdown-menu-arrow::before {
      content: "";
      width: 13px;
      height: 13px;
      background: #fff;
      position: absolute;
      top: -7px;
      right: 20px;
      transform: rotate(45deg);
      border-top: 1px solid #eaedf1;
      border-left: 1px solid #eaedf1;
    }
  }
  
  @-webkit-keyframes dropdown-animate {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  
    0% {
      opacity: 0;
    }
  }
  
  @keyframes dropdown-animate {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  
    0% {
      opacity: 0;
    }
  }
  
  /* Light Backgrounds */
  
  .bg-primary-light {
    background-color: $bg-primary-light;
    border-color: $bg-primary-light;
  }
  
  .bg-secondary-light {
    background-color: $bg-secondary-light;
    border-color: $bg-secondary-light;
  }
  
  .bg-success-light {
    background-color: $bg-success-light;
    border-color: $bg-success-light;
  }
  
  .bg-danger-light {
    background-color: $bg-danger-light;
    border-color: $bg-danger-light;
  }
  
  .bg-warning-light {
    background-color: $bg-warning-light;
    border-color: $bg-warning-light;
  }
  
  .bg-info-light {
    background-color: $bg-info-light;
    border-color: $bg-info-light;
  }
  
  .bg-dark-light {
    background-color: $bg-dark-light;
    border-color: $bg-dark-light;
  }
  
  /* Card */
  
  .card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .card-header, .card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
  }
  
  .card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: $main-title-color;
    font-family: "Poppins", sans-serif;
  
    span {
      color: #899bbd;
      font-size: 14px;
      font-weight: 400;
    }
  }
  
  .card-body {
    padding: 0 20px 20px 20px;
  }
  
  .card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }
  
  /* Alerts */
  
  .alert-heading {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
  }
  
  /* Close Button */
  
  .btn-close {
    background-size: 25%;
  
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  
  /* Accordion */
  
  .accordion-item {
    border: 1px solid #ebeef4;
  }
  
  .accordion-button {
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  
    &:not(.collapsed) {
      color: $main-title-color;
      background-color: $main-background;
    }
  }
  
  .accordion-flush {
    .accordion-button {
      padding: 15px 0;
      background: none;
      border: 0;
  
      &:not(.collapsed) {
        box-shadow: none;
        color: $navlink-color;
      }
    }
  
    .accordion-body {
      padding: 0 0 15px 0;
      color: #3e4f6f;
      font-size: 15px;
    }
  }
  
  /* Breadcrumbs */
  
  .breadcrumb {
    font-size: 14px;
    font-family: "Nunito", sans-serif;
    color: #899bbd;
    font-weight: 600;
  
    a {
      color: #899bbd;
      transition: 0.3s;
  
      &:hover {
        color: #51678f;
      }
    }
  
    .breadcrumb-item::before {
      color: #899bbd;
    }
  
    .active {
      color: #51678f;
      font-weight: 600;
    }
  }
  
  /* Bordered Tabs */
  
  .nav-tabs-bordered {
    border-bottom: 2px solid #ebeef4;
  
    .nav-link {
      margin-bottom: -2px;
      border: none;
      color: #2c384e;
  
      &:hover, &:focus {
        color: $navlink-color;
      }
  
      &.active {
        background-color: #fff;
        color: $navlink-color;
        border-bottom: 2px solid $navlink-color;
      }
    }
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  
  .logo {
    line-height: 1;
  
    img {
      max-height: 50px;
      margin-right: 6px;
    }
  
    span {
      font-size: 26px;
      font-weight: 700;
      color: $main-title-color;
      font-family: "Nunito", sans-serif;
    }
  }
  
  @media (min-width: 1200px) {
    .logo {
      width: 280px;
    }
  }
  
  .header {
    transition: all 0.5s;
    z-index: 997;
    height: 60px;
    box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    padding-left: 20px;
  
    /* Toggle Sidebar Button */
    /* Search Bar */
  
    .toggle-sidebar-btn {
      font-size: 32px;
      padding-left: 10px;
      cursor: pointer;
      color: $main-title-color;
    }
  
    .search-bar {
      min-width: 360px;
      padding: 0 20px;
    }
  
    .search-form {
      width: 100%;
  
      input {
        border: 0;
        font-size: 14px;
        color: $main-title-color;
        border: 1px solid rgba(1, 41, 112, 0.2);
        padding: 7px 38px 7px 8px;
        border-radius: 3px;
        transition: 0.3s;
        width: 100%;
  
        &:focus, &:hover {
          outline: none;
          box-shadow: 0 0 10px 0 rgba(1, 41, 112, 0.15);
          border: 1px solid rgba(1, 41, 112, 0.3);
        }
      }
  
      button {
        border: 0;
        padding: 0;
        margin-left: -30px;
        background: none;
  
        i {
          color: $main-title-color;
        }
      }
    }
  }
  
  @media (max-width: 1199px) {
    .header {
      .search-bar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(1, 41, 112, 0.1);
        background: white;
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
      }
  
      .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  
  /*--------------------------------------------------------------
  # Header Nav
  --------------------------------------------------------------*/
  
  .header-nav {
    ul {
      list-style: none;
    }
  
    > ul {
      margin: 0;
      padding: 0;
    }
  
    .nav-icon {
      font-size: 22px;
      color: $main-title-color;
      margin-right: 25px;
      position: relative;
    }
  
    .nav-profile {
      color: $main-title-color;
  
      img {
        max-height: 36px;
      }
  
      span {
        font-size: 14px;
        font-weight: 600;
      }
    }
  
    .badge-number {
      position: absolute;
      inset: -2px -5px auto auto;
      font-weight: normal;
      font-size: 12px;
      padding: 3px 6px;
    }
  
    .notifications {
      inset: 8px -15px auto auto !important;
  
      .notification-item {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        transition: 0.3s;
  
        i {
          margin: 0 20px 0 10px;
          font-size: 24px;
        }
  
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
        }
  
        p {
          font-size: 13px;
          margin-bottom: 3px;
          color: #919191;
        }
  
        &:hover {
          background-color: $main-background;
        }
      }
    }
  
    .messages {
      inset: 8px -15px auto auto !important;
  
      .message-item {
        padding: 15px 10px;
        transition: 0.3s;
  
        a {
          display: flex;
        }
  
        img {
          margin: 0 20px 0 10px;
          max-height: 40px;
        }
  
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          color: #444444;
        }
  
        p {
          font-size: 13px;
          margin-bottom: 3px;
          color: #919191;
        }
  
        &:hover {
          background-color: $main-background;
        }
      }
    }
  
    .profile {
      min-width: 240px;
      padding-bottom: 0;
      top: 8px !important;
  
      .dropdown-header {
        h6 {
          font-size: 18px;
          margin-bottom: 0;
          font-weight: 600;
          color: #444444;
        }
  
        span {
          font-size: 14px;
        }
      }
  
      .dropdown-item {
        font-size: 14px;
        padding: 10px 15px;
        transition: 0.3s;
  
        i {
          margin-right: 10px;
          font-size: 18px;
          line-height: 0;
        }
  
        &:hover {
          background-color: $main-background;
        }
      }
    }
  }
  
  /*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
  
  .sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $sidebar-color transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
  
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #fff;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: $sidebar-color;
    }
  }
  
  @media (max-width: 1199px) {
    .sidebar {
      left: -300px;
    }
  }
  
  @media (min-width: 1200px) {
    #main, #footer {
      margin-left: 300px;
    }
  }
  
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .toggle-sidebar {
      #main, #footer {
        margin-left: 0;
      }
  
      .sidebar {
        left: -300px;
      }
    }
  }
  
  .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
  
    li {
      padding: 0;
      margin: 0;
      list-style: none;
    }
  
    .nav-item {
      margin-bottom: 5px;
    }
  
    .nav-heading {
      font-size: 11px;
      text-transform: uppercase;
      color: #899bbd;
      font-weight: 600;
      margin: 10px 0 5px 15px;
    }
  
    .nav-link {
      display: flex;
      align-items: center;
      font-size: 15px;
      font-weight: 600;
      color: $navlink-color;
      transition: 0.3;
      background: $main-background;
      padding: 10px 15px;
      border-radius: 4px;
  
      i {
        font-size: 16px;
        margin-right: 10px;
        color: $navlink-color;
      }
  
      &.collapsed {
        color: $main-title-color;
        background: #fff;
  
        i {
          color: #899bbd;
        }
      }
  
      &:hover {
        color: $navlink-color;
        background: $main-background;
  
        i {
          color: $navlink-color;
        }
      }
  
      .bi-chevron-down {
        margin-right: 0;
        transition: transform 0.2s ease-in-out;
      }
  
      &:not(.collapsed) .bi-chevron-down {
        transform: rotate(180deg);
      }
    }
  
    .nav-content {
      padding: 5px 0 0 0;
      margin: 0;
      list-style: none;
  
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: $main-title-color;
        transition: 0.3;
        padding: 10px 0 10px 40px;
        transition: 0.3s;
  
        i {
          font-size: 6px;
          margin-right: 8px;
          line-height: 0;
          border-radius: 50%;
        }
  
        &:hover {
          color: $navlink-color;
        }
  
        &.active {
          color: $navlink-color;
  
          i {
            background-color: $navlink-color;
          }
        }
      }
    }
  }
  
  /*--------------------------------------------------------------
  # Dashboard
  --------------------------------------------------------------*/
  /* Filter dropdown */
  
  .dashboard {
    .filter {
      position: absolute;
      right: 0px;
      top: 15px;
  
      .icon {
        color: $sidebar-color;
        padding-right: 20px;
        padding-bottom: 5px;
        transition: 0.3s;
        font-size: 16px;
  
        &:hover, &:focus {
          color: $navlink-color;
        }
      }
  
      .dropdown-header {
        padding: 8px 15px;
  
        h6 {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 600;
          letter-spacing: 1px;
          color: $sidebar-color;
          margin-bottom: 0;
          padding: 0;
        }
      }
  
      .dropdown-item {
        padding: 8px 15px;
      }
    }
  
    .info-card {
      padding-bottom: 10px;
  
      h6 {
        font-size: 28px;
        color: $main-title-color;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }
    }
  
    .card-icon {
      font-size: 32px;
      line-height: 0;
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  
    .sales-card .card-icon {
      color: $navlink-color;
      background: $main-background;
    }
  
    .revenue-card .card-icon {
      color: #2eca6a;
      background: #e0f8e9;
    }
  
    .customers-card .card-icon {
      color: #ff771d;
      background: #ffecdf;
    }
  
    .activity {
      font-size: 14px;
  
      .activity-item {
        .activity-label {
          color: #888;
          position: relative;
          flex-shrink: 0;
          flex-grow: 0;
          min-width: 64px;
  
          &::before {
            content: "";
            position: absolute;
            right: -11px;
            width: 4px;
            top: 0;
            bottom: 0;
            background-color: #eceefe;
          }
        }
  
        .activity-badge {
          margin-top: 3px;
          z-index: 1;
          font-size: 11px;
          line-height: 0;
          border-radius: 50%;
          flex-shrink: 0;
          border: 3px solid #fff;
          flex-grow: 0;
        }
  
        .activity-content {
          padding-left: 10px;
          padding-bottom: 20px;
        }
  
        &:first-child .activity-label::before {
          top: 5px;
        }
  
        &:last-child .activity-content {
          padding-bottom: 0;
        }
      }
    }
  
    .news {
      .post-item + .post-item {
        margin-top: 15px;
      }
  
      img {
        width: 80px;
        float: left;
        border-radius: 5px;
      }
  
      h4 {
        font-size: 15px;
        margin-left: 95px;
        font-weight: bold;
        margin-bottom: 5px;
  
        a {
          color: $main-title-color;
          transition: 0.3s;
  
          &:hover {
            color: $navlink-color;
          }
        }
      }
  
      p {
        font-size: 14px;
        color: #777777;
        margin-left: 95px;
      }
    }
  
    .recent-sales {
      font-size: 14px;
  
      .table thead {
        background: $main-background;
  
        th {
          border: 0;
        }
      }
  
      .dataTable-top {
        padding: 0 0 10px 0;
      }
  
      .dataTable-bottom {
        padding: 10px 0 0 0;
      }
    }
  
    .top-selling {
      font-size: 14px;
  
      .table {
        thead {
          background: $main-background;
  
          th {
            border: 0;
          }
        }
  
        tbody td {
          vertical-align: middle;
        }
      }
  
      img {
        border-radius: 5px;
        max-width: 60px;
      }
    }
  }
  
  /* Info Cards */
  
  /* Activity */
  
  /* News & Updates */
  
  /* Recent Sales */
  
  /* Top Selling */
  
  /*--------------------------------------------------------------
  # Icons list page
  --------------------------------------------------------------*/
  
  .iconslist {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1.25rem;
    padding-top: 15px;
  
    .icon {
      background-color: #fff;
      border-radius: 0.25rem;
      text-align: center;
      color: $main-title-color;
      padding: 15px 0;
    }
  
    i {
      margin: 0.25rem;
      font-size: 2.5rem;
    }
  
    .label {
      font-family: var(--bs-font-monospace);
      display: inline-block;
      width: 100%;
      overflow: hidden;
      padding: 0.25rem;
      font-size: 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #666;
    }
  }
  
  /*--------------------------------------------------------------
  # Profie Page
  --------------------------------------------------------------*/
  
  .profile {
    .profile-card {
      img {
        max-width: 120px;
      }
  
      h2 {
        font-size: 24px;
        font-weight: 700;
        color: #2c384e;
        margin: 10px 0 0 0;
      }
  
      h3 {
        font-size: 18px;
      }
  
      .social-links a {
        font-size: 20px;
        display: inline-block;
        color: rgba(1, 41, 112, 0.5);
        line-height: 0;
        margin-right: 10px;
        transition: 0.3s;
  
        &:hover {
          color: $main-title-color;
        }
      }
    }
  
    .profile-overview {
      .row {
        margin-bottom: 20px;
        font-size: 15px;
      }
  
      .card-title {
        color: $main-title-color;
      }
  
      .label {
        font-weight: 600;
        color: rgba(1, 41, 112, 0.6);
      }
    }
  
    .profile-edit {
      label {
        font-weight: 600;
        color: rgba(1, 41, 112, 0.6);
      }
  
      img {
        max-width: 120px;
      }
    }
  }
  
  /*--------------------------------------------------------------
  # F.A.Q Page
  --------------------------------------------------------------*/
  
  .faq .basic {
    h6 {
      font-size: 18px;
      font-weight: 600;
      color: $navlink-color;
    }
  
    p {
      color: #6980aa;
    }
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  
  .contact {
    .info-box {
      padding: 28px 30px;
  
      i {
        font-size: 38px;
        line-height: 0;
        color: $navlink-color;
      }
  
      h3 {
        font-size: 20px;
        color: $main-title-color;
        font-weight: 700;
        margin: 20px 0 10px 0;
      }
  
      p {
        padding: 0;
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
  
    .php-email-form {
      .error-message {
        display: none;
        color: #fff;
        background: #ed3c0d;
        text-align: left;
        padding: 15px;
        margin-bottom: 24px;
        font-weight: 600;
      }
  
      .sent-message {
        display: none;
        color: #fff;
        background: #18d26e;
        text-align: center;
        padding: 15px;
        margin-bottom: 24px;
        font-weight: 600;
      }
  
      .loading {
        display: none;
        background: #fff;
        text-align: center;
        padding: 15px;
        margin-bottom: 24px;
  
        &:before {
          content: "";
          display: inline-block;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          margin: 0 10px -6px 0;
          border: 3px solid #18d26e;
          border-top-color: #eee;
          -webkit-animation: animate-loading 1s linear infinite;
          animation: animate-loading 1s linear infinite;
        }
      }
  
      input, textarea {
        border-radius: 0;
        box-shadow: none;
        font-size: 14px;
        border-radius: 0;
      }
  
      input:focus, textarea:focus {
        border-color: $navlink-color;
      }
  
      input {
        padding: 10px 15px;
      }
  
      textarea {
        padding: 12px 15px;
      }
  
      button[type=submit] {
        background: $navlink-color;
        border: 0;
        padding: 10px 30px;
        color: #fff;
        transition: 0.4s;
        border-radius: 4px;
  
        &:hover {
          background: #5969f3;
        }
      }
    }
  }
  
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Error 404
  --------------------------------------------------------------*/
  
  .error-404 {
    padding: 30px;
  
    h1 {
      font-size: 180px;
      font-weight: 700;
      color: $navlink-color;
      margin-bottom: 0;
      line-height: 150px;
    }
  
    h2 {
      font-size: 24px;
      font-weight: 700;
      color: $main-title-color;
      margin-bottom: 30px;
    }
  
    .btn {
      background: #51678f;
      color: #fff;
      padding: 8px 30px;
  
      &:hover {
        background: #3e4f6f;
      }
    }
  }
  
  @media (min-width: 992px) {
    .error-404 img {
      max-width: 50%;
    }
  }
  
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  
  .footer {
    padding: 20px 0;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
  
    .copyright {
      text-align: center;
      color: $main-title-color;
    }
  
    .credits {
      padding-top: 5px;
      text-align: center;
      font-size: 13px;
      color: $main-title-color;
    }
  }