$primary: #0070a2;
$secondary: #e0e0e5;
$tertiary: #820933;
$success: #157347;
$main-background: #f4f4f5;
$main-font-color: #444444;
$main-link-color: $primary;
$main-link-hover-color: #004666;
$main-title-color: #004666;
$bg-primary-light: #c2edff;
$bg-secondary-light: #e2e3e5;
$bg-success-light: #d1e7dd;
$bg-danger-light: #f8d7da;
$bg-warning-light: #fff3cd;
$bg-info-light: #cff4fc;
$bg-dark-light: #d3d3d4;
$sidebar-color: #aab7cf;
$navlink-color: $main-link-color;
$outline-info: $tertiary;
$border-color: #dee2e6;
$sub-title: #899bbd;
